<template>
  <div class="w-full bg-white min-h-view text-black h-view overflow-auto">
    <!-- DESKTOP VERSION -->
    <div class="max-w-screen-lg hidden md:flex flex-row mx-auto">
      <div class="w-1/6 flex flex-col pl-4 pt-16 mr-3 min-h-view">
        <button
          v-for="(infoTab, idx) in informationArticles"
          :key="idx"
          @click="toggleActive(idx)"
          class="text-md font-medium p-1 text-left focus:outline-none"
          :class="[idx == tabIndex ? 'font-bold text-ul-orange-400' : '']"
        >
          {{ infoTab.title }}
        </button>
      </div>
      <div class="border my-16 border-ul-orange-400"></div>
      <div class="w-5/6 p-16 text-black h-view overflow-auto">
        <div class="flex flex-row space-x-4 items-center pb-4">
          <PhosphorIcon :iconName="activeTab.icon || 'info'" class="text-2xl" />
          <h1 class="text-xl font-bold">{{ activeTab.title }}</h1>
        </div>
        <div class="v-html" v-html="activeTab.text" />
      </div>
    </div>
    <!-- MOBILE VERSION -->
    <div class="md:hidden sm:my-4 sm:mx-8">
      <div v-for="(infoTab, idx) in informationArticles" :key="idx" class="w-full border-b border-ul-orange-300">
        <button
          @click="toggleActive(idx)"
          class="w-full h-16 flex text-lg relative items-center"
          :class="idx === tabIndex && isOpen ? 'bg-ul-orange-200 font-semibold' : ''"
        >
          <PhosphorIcon :iconName="infoTab.icon || 'info'" class="text-2xl mx-5" />
          <div class="flex grow h-16 items-center">
            <span>{{ infoTab.title }}</span>
          </div>
          <!-- Chevron (arrow) -->
          <svg
            viewBox="0 0 48 48"
            class="w-8 h-8 m-4 duration-200"
            :class="idx === tabIndex && isOpen ? '-rotate-180 text-ul-orange-400' : ' text-ul-orange-300'"
          >
            <path fill="currentColor" d="m14.83,16.42l9.17,9.17l9.17,-9.17l2.83,2.83l-12,12l-12,-12l2.83,-2.83z" />
          </svg>
        </button>
        <div
          class="overflow-y-scroll overflow-x-hidden ul-scroll transition-all duration-300 text-black bg-ul-orange-200 bg-opacity-25 shadow-inner"
          :class="idx === tabIndex && isOpen ? 'max-h-1/2-screen' : 'max-h-0'"
        >
          <div class="p-4 v-html" v-html="infoTab.text" />
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { computed } from 'vue'
import { watch } from 'vue'
import { onMounted } from 'vue'

import { useRoute } from 'vue-router'
import PageFooter from '../components/PageFooter.vue'
import PhosphorIcon from '../components/PhosphorIcon.vue'
import { useInformationStore } from '../stores/information'

const informationStore = useInformationStore()

const tabIndex = ref(0)
// boolean for whether mobile menu is open
const isOpen = ref(false)

const informationArticles = computed(() => informationStore.articles.toSorted((a, b) => b.priority - a.priority))
const activeTab = computed(
  () => informationArticles.value[tabIndex.value] ?? { icon: '', title: '', text: '', priority: 0 }
)

function slugifyString(value: string) {
  return value
    .trim()
    .toLocaleLowerCase()
    .replace('æ', 'ae')
    .replace('ø', 'oe')
    .replace('å', 'aa')
    .replace('+', 'pluss')
    .replace(/\s/g, '-')
    .replace(/[^a-z-]/g, '')
}
const slugifiedTabNames = computed(() => informationArticles.value.map((info) => slugifyString(info.title)))

function toggleActive(index: number) {
  if (tabIndex.value === index && isOpen.value) {
    isOpen.value = false
  } else {
    isOpen.value = true
    tabIndex.value = index
  }
}

function goToHashTab() {
  const hashValue = window.location.hash.replace('#', '')
  const index = slugifiedTabNames.value.findIndex((tabName) => tabName === hashValue)
  tabIndex.value = Math.max(index, 0)
}

const route = useRoute()
watch(route, goToHashTab)

watch(tabIndex, (newValue: number) => {
  window.location.hash = slugifiedTabNames.value[newValue] ?? undefined
})

onMounted(() => {
  goToHashTab()
})
</script>

<style lang="scss"></style>
