<template>
  <div class="flex flex-col justify-center items-center bg-ul-orange-400 text-ul-orange-50 relative overflow-hidden">
    <QuarterCircle :radius="64" direction="southwest" class="absolute top-0 right-0" />
    <HalfCircle :radius="64" direction="east" class="absolute left-0" />

    <div class="w-full flex flex-row justify-around md:max-w-screen-md px-2 my-8 sm:my-20 z-1">
      <a
        v-for="link in socialMediaLinks"
        :key="link.href"
        :href="link.href"
        target="_blank"
        rel="noopener noreferrer"
        :title="'UL på ' + link.name"
      >
        <Icon :icon="link.icon" class="h-10 hover:text-opacity-75" />
      </a>
    </div>
    <div class="w-32 mb-8 sm:mb-12 border-solid border-t border-ul-orange-50 z-1" />
    <p class="z-1">Festivalen arrangeres av:</p>
    <div class="flex sm:flex-row flex-col mt-4 sm:mt-12 z-1">
      <a
        v-for="organizer in organizers"
        :key="organizer.name"
        class="px-16 py-4 mx-6 sm:mb-0 mb-3 border-solid border-2 border-ul-orange-50 font-bold text-center opacity-75 hover:opacity-100 bg-ul-orange-400 bg-opacity-40 hover:bg-opacity-75 duration-300"
        :href="organizer.link"
        rel="noopener noreferrer"
      >
        {{ organizer.name }}
      </a>
    </div>
    <div class="m-12 flex flex-col justify-center items-center text-center z-1">
      <p class="italic">&copy; Misjonssambandet ung</p>
      <p class="italic text-xs font-light">Nettsiden vedlikeholdes av <a href="https://vitterso.net" target="_blank" class="underline underline-offset-[3px]">Sebastian Vittersø</a>.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import facebookLogo from '../assets/facebook-app-logo.svg'
import instagramLogo from '../assets/instagram-logo.svg'
import podcastLogo from '../assets/podcast.svg'
import tiktokLogo from '../assets/tiktoklogo.svg'
import youtubeLogo from '../assets/youtube.svg'
import Icon from './Icon.vue'
import HalfCircle from './circles/HalfCircle.vue'
import QuarterCircle from './circles/QuarterCircle.vue'
import type { IconKey } from './icons'

const organizers = [
  {
    name: 'Misjonssambandet ung',
    link: 'https://nlm.no/nlmung/',
  },
  {
    name: ' ImF-UNG',
    link: 'https://imf-ung.no/',
  },
]

const socialMediaLinks = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/unglandsmote/',
    asset: facebookLogo,
    icon: 'facebook',
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/unglandsmote/',
    asset: instagramLogo,
    icon: 'instagram',
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/channel/UCMZYb2fzkSCpFaLlp8gZTQw',
    asset: youtubeLogo,
    icon: 'youtube',
  },
  {
    name: 'TikTok',
    href: 'https://www.tiktok.com/@unglandsmote',
    asset: tiktokLogo,
    icon: 'tiktok',
  },
  {
    name: 'Podcast',
    href: 'https://open.spotify.com/show/6Gq42YIUXB0ECLACSl8OUd?si=3pnTcpACR7O_5p6YUojUqQ',
    asset: podcastLogo,
    icon: 'podcast',
  },
] satisfies {
  name: string
  href: string
  asset: string
  icon: IconKey
}[]
</script>

<style lang="scss"></style>
