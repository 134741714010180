<template>
  <div class="home h-view overflow-auto">
    <!-- Desktop version -->
    <div class="hidden lg:block h-view bg-ul-orange-300 text-ul-orange-100 font-display font-medium relative">
      <div class="flex flex-row h-5/6">
        <div class="relative w-1/2 text-white">
          <!-- <img
          :srcSet="pinsImageSourceSet"
          :sizes="pinsSizes"
          :src="pinsImage2k"
          role="presentation"
          class="w-full h-full object-cover"
          /> -->
          <img :src="ul24Image" role="presentation" class="w-full h-full object-cover" />
          <div class="absolute inset-x-0 bottom-1/12 flex flex-col items-center justify-center gap-2">
            <h1 class="text-center leading-none text-10xl xl:text-11xl xxl:text-12xl xxxl:text-14xl">UL25</h1>
            <h2 class="text-center leading-none text-4xl xl:text-5xl xxl:text-6xl xxxl:text-7xl">
              8.-12. juli i Stavanger!
            </h2>
          </div>
          <!-- <h2 class="absolute inset-x-0 bottom-1/4 text-center text-4xl xl:text-5xl xxl:text-6xl xxxl:text-7xl">
            Tema: Sted
          </h2> -->
        </div>
        <div class="flex flex-col w-1/2">
          <VimeoPlayer :videoId="1047469975" class="flex-shrink-0" />
          <!-- <div v-if="isStreamActive" class="h-3/5 bg-black">
            <iframe
              id="ytplayer"
              type="text/html"
              width="100%"
              height="100%"
              :src="streamEmbedUrl"
              frameborder="0"
              allow="fullscreen;"
            />
          </div> -->
          <!-- <div class="flex flex-row h-3/5">
            <div class="flex flex-col w-3/5">
              <div class="flex flex-row h-full">
                <div class="flex flex-col w-1/3">
                  <img :src="lotteryImage" role="presentation" class="w-full h-1/2 object-cover" />
                  <img :src="praise2Image" role="presentation" class="w-full h-1/2 object-cover" />
                </div>
                <div class="flex flex-col w-2/3">
                  <img :src="bikeImage" role="presentation" class="w-full h-full object-cover" />
                </div>
              </div>
              <!- <a
                class="h-1/3 flex flex-row justify-center items-center text-center text-3xl xl:text-4xl xxl:text-6xl xxxl:text-7xl bg-ul-orange-400 underline underline-offset-8"
                href="https://www.youtube.com/@ul-festivalsomforandrer3406"
              >
                Se UL24 i reprise!
              </a> ->
            </div>
            <div class="flex flex-col w-2/5">
              <img :src="wetsuitImage" role="presentation" class="w-full h-3/8 object-cover" />
              <div
                class="flex justify-center items-center h-1/4 text-center text-4xl xl:text-5xl xxl:text-6xl xxxl:text-8xl"
              >
                8.-12. juli
              </div>
              <img :src="cheerImage" role="presentation" class="w-full h-3/8 object-cover" />
            </div>
          </div> -->
          <div
            class="h-1/5 flex justify-center items-center text-center text-4xl xl:text-5xl xxl:text-6xl xxxl:text-7xl"
          >
            Stavanger
          </div>
          <div class="min-h-1/5 flex-grow">
            <img :src="praiseImage" role="presentation" class="w-full h-full object-cover" />
          </div>
        </div>
      </div>
      <div class="flex flex-row w-full h-1/6">
        <div class="flex flex-row w-1/2 h-full">
          <img :src="discGolfImage" role="presentation" class="w-1/4 h-full object-cover" />
          <!-- <div
            v-if="isStreamActive"
            class="flex justify-center items-center h-full flex-grow text-center text-4xl xl:text-5xl xxl:text-6xl xxxl:text-8xl"
          >
            23.-27. juli
          </div>
          <GridImageButton v-else :image="screamImage" text="Kjøp billett!" link="/billetter" class="w-3/8 text-5xl" /> -->
          <img :src="screamImage" role="presentation" class="w-3/8 h-full object-cover" />
          <img :src="surfImage" role="presentation" class="w-1/4 h-full object-cover" />
          <img :src="confettiImage" role="presentation" class="w-1/8 h-full object-cover" />
        </div>
        <div class="flex flex-row w-1/2 h-full">
          <img :src="smileImage" role="presentation" class="w-1/4 h-full object-cover" />
          <div
            class="flex justify-center items-center w-3/8 h-full text-center text-3xl xl:text-4xl xxl:text-5xl xxxl:text-6xl xxxxl:text-7xl"
          >
            Festival som forandrer
          </div>
          <img :src="shadesImage" role="presentation" class="w-3/8 h-full object-cover" />
        </div>
      </div>
    </div>

    <!-- Mobile version -->
    <div class="lg:hidden h-view bg-ul-orange-300 text-ul-orange-100 font-display font-medium relative">
      <div class="flex flex-col h-full">
        <VimeoPlayer :videoId="1047469975" class="flex-shrink-0" />

        <!-- <div class="relative w-full h-1/3 text-white">
          <!- <img
            :srcSet="pinsImageSourceSet"
            :sizes="pinsSizes"
            :src="pinsImage2k"
            role="presentation"
            class="w-full h-full object-cover"
          /> ->
          <img :src="ul24Image" role="presentation" class="w-full h-full object-cover" />
          <div class="absolute inset-x-0 bottom-8 flex flex-col items-center justify-center gap-2">
            <h1 class="text-center leading-none text-6xl xs:text-7xl sm:text-8xl md:text-9xl">UL25</h1>
            <h2 class="text-center leading-none text-2xl xs:text-3xl sm:text-4xl md:text-5xl">
              8.-12. juli i Stavanger!
            </h2>
          </div>
        </div> -->

        <div class="flex flex-row h-1/8">
          <img :src="wetsuitImage" role="presentation" class="w-2/5 h-full object-cover" />
          <div class="flex justify-center items-center w-3/5 text-center text-4xl xs:text-5xl sm:text-6xl md:text-7xl">
            8.-12. juli
          </div>
        </div>

        <!-- <a
          :href="streamLinkUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="h-1/6 flex justify-center items-center text-center text-4xl xs:text-5xl sm:text-6xl md:text-8xl underline underline-offset-8 bg-ul-orange-400"
        >
          Se UL direkte nå!
        </a> -->
        <!-- <a
          href="https://www.youtube.com/@ul-festivalsomforandrer3406"
          target="_blank"
          rel="noopener noreferrer"
          class="h-1/6 flex justify-center items-center text-center text-3xl xs:text-4xl sm:text-5xl md:text-7xl underline underline-offset-8 bg-ul-orange-400"
        >
          Se UL24 i reprise!
        </a> -->
        <!-- <img :src="praiseImage" role="presentation" class="w-full h-1/6 object-cover" /> -->

        <div class="h-1/8 flex justify-center items-center text-center text-2xl xs:text-3xl sm:text-4xl md:text-5xl">
          Stavanger
        </div>

        <div class="flex flex-row h-1/8 flex-grow">
          <img :src="smileImage" role="presentation" class="w-1/3 h-full object-cover" />
          <img :src="shadesImage" role="presentation" class="w-1/2 h-full object-cover" />
          <img :src="discGolfImage" role="presentation" class="w-1/6 h-full object-cover" />
        </div>

        <div class="flex flex-row h-1/8">
          <div class="flex justify-center items-center w-2/5 text-center text-2xl xs:text-3xl sm:text-4xl md:text-5xl">
            Festival som forandrer
          </div>
          <img :src="screamImage" role="presentation" class="w-3/5 h-full object-cover" />
        </div>
      </div>
    </div>

    <div class="flex flex-col bg-ul-orange-400">
      <AboutArticle v-for="(article, idx) in homeAboutArticles" :key="article.id" :article="article" :index="idx" />
    </div>

    <div class="flex justify-center items-center bg-ul-orange-200">
      <div class="max-w-screen-xl flex flex-col lg:flex-row-reverse justify-center items-center gap-12 p-12">
        <img :src="plussPlakatImage" role="presentation" class="lg:w-1/2 object-cover" />
        <div class="flex flex-col gap-8">
          <p class="text-center lg:text-right text-lg">
            Velkommen på UL+! Festival i festivalen UL, for deg som er 22 år eller eldre. Vi har vår egen lounge, eget
            program- og alt er skreddersydd for oss!
          </p>
          <router-link
            to="ul-pluss"
            class="text-ul-orange-400 font-display font-bold text-2xl py-2 px-4 border-2 border-ul-orange-400 text-center"
          >
            Les mer
          </router-link>
        </div>
      </div>
    </div>

    <!-- <div class="flex flex-col bg-ul-orange-300 py-8 gap-8 items-center justify-center">
      <h3 class="text-ul-orange-100 font-display text-5xl flex justify-center items-center text-center">Billetter</h3>
      <div
        v-if="tickets.length > 0"
        class="flex flex-col lg:flex-row lg:flex-wrap justify-center items-center gap-8 max-w-screen-xl"
      >
        <Ticket v-for="(ticket, idx) in tickets" :key="idx" :ticket="ticket" />
      </div>
    </div> -->

    <PageFooter />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import confettiImage from '../assets/images/confetti.jpg'
import discGolfImage from '../assets/images/discgolf.png'
import pinsImage1k from '../assets/images/pins-1k.jpg'
import pinsImage2k from '../assets/images/pins-2k.jpg'
import pinsImage500 from '../assets/images/pins-500.png'
import plussPlakatImage from '../assets/images/plakat-ulpluss.jpeg'
import praiseImage from '../assets/images/praise.jpg'
import screamImage from '../assets/images/scream.png'
import shadesImage from '../assets/images/shades.jpg'
import smileImage from '../assets/images/smile.jpg'
import surfImage from '../assets/images/surf.jpg'
import ul24Image from '../assets/images/ul24.jpg'
import wetsuitImage from '../assets/images/wetsuit.jpg'
import AboutArticle from '../components/AboutArticle.vue'
import PageFooter from '../components/PageFooter.vue'
import VimeoPlayer from '../components/VimeoPlayer.vue'
import { useAboutStore } from '../stores/about'
import { useStreamStore } from '../stores/stream'
import { useTicketStore } from '../stores/ticket'

const pinsImageSourceSet = `${pinsImage500} 500w, ${pinsImage1k} 1000w, ${pinsImage2k} 2000w`
const pinsSizes = '(max-width: 500px) 500px, (max-width: 1000px) 1000px, 2000px'

const homeAboutArticles = useAboutStore()
  .articles.toSorted((a, b) => b.priority - a.priority)
  .filter((article) => article.frontpage)

const ticketStore = useTicketStore()
const tickets = computed(() => ticketStore.tickets)

const streamStore = useStreamStore()
const streamId = computed(() => streamStore.streamId)
const isStreamActive = computed(() => (streamId.value?.length ?? '') > 0)
const streamEmbedUrl = computed(() => `https://www.youtube.com/embed/${streamId.value}?origin=https://ul.no`)
const streamLinkUrl = computed(() => `https://www.youtube.com/watch?v=${streamId.value}`)
const nextStreamDate = computed(() => streamStore.nextStreamDate)
const formattedNextStreamDate = computed(() => {
  if (!nextStreamDate.value) return ''
  const date = new Date(nextStreamDate.value)
  return date.toLocaleDateString('nb-NO', { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' })
})
</script>

<style scoped>
.home div {
  @apply overflow-hidden;
}
</style>
