<template>
  <div ref="vimeoContainerRef"></div>
</template>

<script setup lang="ts">
import VimeoPlayer, { type Options } from '@vimeo/player';
import { onMounted, ref } from 'vue';

type Props = {videoId: number}
const props = defineProps<Props>()

const vimeoContainerRef = ref<HTMLDivElement>()
const options: Options = {
  id: props.videoId,
  loop: false,
  autoplay: true,  
  controls: true,
  playsinline: true,
  responsive: true,
  muted: true,
}

const player = ref<VimeoPlayer>()

onMounted(() => {
  if (vimeoContainerRef.value === undefined) {
    throw new Error('vimeoContainerRef is not defined')
  }
  player.value = new VimeoPlayer(vimeoContainerRef.value, options)
})
</script>

<style></style>
