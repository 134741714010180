<template>
  <nav class="h-16 w-full sticky top-0 z-3 bg-ul-orange-400 shadow pt-1 text-right" style="padding-top: 2px">
    <router-link to="/">
      <p class="font-display left-3 top-3 absolute h-10 text-black text-4xl font-semibold">UL25</p>
    </router-link>
    <div class="text-right text-black">
      <router-link
        v-for="(navLocation, idx) in routes"
        :key="idx"
        :to="navLocation.path"
        class="nav-location hidden lg:inline-block cursor-pointer select-none min-w-16 py-4 px-3 font-medium text-center hover:text-opacity-60 last:mr-2"
        active-class="nav-location-active border-black border-b-2 border-solid"
        tag="div"
      >
        {{ navLocation.name }}
      </router-link>
    </div>
    <div @click="openSidebar" class="lg:hidden inline-block m-3 hover:bg-white hover:bg-opacity-25 cursor-pointer">
      <Icon icon="menu" class="h-10 text-black" />
    </div>
    <div
      sidebar-backdrop
      @click="closeSidebar"
      class="lg:hidden fixed inset-0 bg-black/50"
      :class="[isOpen ? '' : 'hidden']"
    />
    <div
      sidebar
      class="lg:hidden fixed h-screen top-0 bg-ul-orange-150 w-72 duration-200 flex flex-col p-4 space-y-2 overflow-auto ul-scroll"
      :class="[isOpen ? 'shadow' : '']"
      :style="`right: ${isOpen ? '0' : '-30rem'}; max-width: 80vw;`"
    >
      <router-link
        @click.native="closeSidebar"
        v-for="(navLocation, idx) in routes"
        :key="idx"
        :to="navLocation.path"
        class="nav-location lg:hidden duration-300 cursor-pointer select-none py-3 px-3 font-semibold text-center text-black border-b-2 border-solid border-transparent hover:text-opacity-60"
        active-class="nav-location-active text-black border-black"
        tag="div"
      >
        {{ navLocation.name }}
      </router-link>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { routes } from '../router'
import Icon from './Icon.vue'

const isOpen = ref(false)
const openSidebar = () => {
  isOpen.value = true
}
const closeSidebar = () => {
  isOpen.value = false
}
</script>

<style></style>
